import * as React from 'react';

import { branch, compose, renderComponent, withProps } from 'recompose';

import createLoadableComponent from 'js/lib/createLoadableComponent';
import { useLocation } from 'js/lib/useRouter';
import user from 'js/lib/user';

import { shouldShowLegalConsolidationAuthFlow } from 'bundles/authentication/utils';
import useMarketingConsentData from 'bundles/user-consent/hooks/useMarketingConsentData';

const LoadableGDPRPage = createLoadableComponent(() => import('bundles/user-consent/components/GDPRPage'));
const LoadableCCPAPage = createLoadableComponent(() => import('bundles/user-consent/components/CCPAPage'));

type MarketingConsentProps = {
  shouldShowCCPAPage: boolean;
  shouldShowGDPRPage: boolean;
  isEnterpriseAdmin: boolean;
  shouldShowMarketingConsent: boolean;
};

function useConsentEligibility() {
  const shouldSkipQuery = !user.isAuthenticatedUser();

  const { query: queryParams } = useLocation();
  const showCCPAWallOverride = queryParams.showCcpaWallOverride === 'true';
  const showGDPRWallOverride = queryParams.showGdprWallOverride === 'true';
  const { shouldShowCCPAMarketingConsent, shouldShowGDPRMarketingConsent, isEnterpriseAdmin } = useMarketingConsentData(
    undefined,
    shouldSkipQuery
  );

  const shouldShowCCPAPage =
    !shouldShowLegalConsolidationAuthFlow() && (showCCPAWallOverride || shouldShowCCPAMarketingConsent);
  const shouldShowGDPRPage = showGDPRWallOverride || shouldShowGDPRMarketingConsent;

  const shouldShowMarketingConsent = shouldShowCCPAPage || shouldShowGDPRPage;

  return { shouldShowMarketingConsent, shouldShowCCPAPage, shouldShowGDPRPage, isEnterpriseAdmin };
}

function MarketingConsent({ shouldShowCCPAPage, shouldShowGDPRPage, isEnterpriseAdmin }: MarketingConsentProps) {
  if (shouldShowCCPAPage) {
    return <LoadableCCPAPage isEnterpriseAdmin={isEnterpriseAdmin} />;
  } else if (shouldShowGDPRPage) {
    return <LoadableGDPRPage isEnterpriseAdmin={isEnterpriseAdmin} />;
  }

  return null;
}

export default (Component: React.ComponentType<MarketingConsentProps>) =>
  compose<MarketingConsentProps, {}>(
    withProps(() => {
      const eligibilityStatus = useConsentEligibility();
      return eligibilityStatus;
    }),
    branch<MarketingConsentProps>(
      ({ shouldShowMarketingConsent }) => !!shouldShowMarketingConsent,
      renderComponent(MarketingConsent)
    )
  )(Component);
